<template>
	<div
		:id="blockId"
		class="block-product-wrapper"
	>
		<ProductSkeletonLoader
			v-if="isLoading || !productData"
			:image-border-radius="imageBorderRadius"
			:text-align="textAlign"
		/>
		<div
			v-else
			class="block-product"
			:class="{ 'block-product--centered': textAlign === 'center' }"
			:style="computedStyles"
		>
			<Carousel
				:images="productData.images"
				:product-title="productData.title"
				:arrows-color="navigationArrowsColor"
				:image-ratio="imageRatio"
				:image-border-radius="imageBorderRadius"
				:is-eager="isEager"
			/>
			<div
				class="block-product__content-wrapper"
				:class="{ 'block-product__content-wrapper--centered': textAlign ==='center' }"
			>
				<h3 class="block-product__title">
					{{ productData.title }}
				</h3>
				<h5 class="block-product__subtitle">
					{{ productData.subtitle }}
				</h5>
				<div
					class="block-product__price-wrapper"
					:class="{ 'block-product__price-wrapper--with-duration': isProductTypeBooking }"
				>
					<p
						v-if="showPrice"
						class="block-product__price body-large"
						:class="{ 'block-product__price--sale': priceData.sale_amount }"
					>
						{{ formatPrice(priceData.amount, priceData.currency_code) }}
					</p>
					<div class="block-product__additional-info">
						<p
							v-if="priceData.sale_amount && showPrice"
							class="block-product__price body-large"
						>
							{{ formatPrice(priceData.sale_amount, priceData.currency_code) }}
						</p>
						<p
							v-if="isProductTypeBooking"
							class="block-product__duration body-large"
						>
							{{ formattedBookingDuration }}
						</p>
					</div>
				</div>
				<p
					v-if="isProductTypeBooking"
					class="block-product__location"
				>
					{{ location }}
				</p>
				<OptionSelect
					v-if="productData.options.length"
					:options="productOptions"
					:value="selectedOption"
					:title="productData.options[0].title"
					class="option-select"
					@set-value="selectedOption = $event"
				/>
				<GridButton
					:type="blockButtonType"
					:content="buttonText"
					class="block-product__button"
					:class="`block-product__button--${blockButtonType}`"
					:is-loading="isCheckoutLoading"
					tag-name="button"
					:disabled="isButtonDisabled"
					@click="handleButtonClick"
				/>
				<Transition name="fade">
					<p
						v-if="isBookingProductInCart"
						class="block-product__notice"
					>
						{{ translations.purchaseToBookAgain }}
					</p>
				</Transition>
				<p
					v-if="productData.description"
					class="block-product__description"
					v-html="productData.description"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Carousel from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/Carousel.vue';
import OptionSelect from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/OptionSelect.vue';
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import formatPrice from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { PRODUCT_TYPE_BOOKING } from '@zyro-inc/site-modules/constants/ecommerce';
import { getFormattedBookingDuration } from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { mapState } from 'vuex';
import ProductSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductSkeletonLoader.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'BlockEcommerceProduct',

	components: {
		GridButton,
		Carousel,
		OptionSelect,
		ProductSkeletonLoader,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
		productData: {
			type: Object,
			default: null,
		},
		blockStyle: {
			type: Object,
			default: () => ({}),
		},
		textColorVars: {
			type: Object,
			default: () => ({}),
		},
		blockButtonText: {
			type: String,
			default: null,
		},
		blockButtonStyle: {
			type: Object,
			default: () => ({}),
		},
		blockButtonType: {
			type: String,
			default: 'primary',
		},
		navigationArrowsColor: {
			type: String,
			default: null,
		},
		imageRatio: {
			type: String,
			default: 'cover',
		},
		imageBorderRadius: {
			type: String,
			default: '0%',
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isCheckoutLoading: {
			type: Boolean,
			default: false,
		},
		canAddToCart: {
			type: Function,
			default: () => true,
		},
		isEager: {
			type: Boolean,
			default: false,
		},
		translations: {
			type: Object,
			default: null,
		},
	},

	emits: ['buy-button-click'],

	data() {
		return {
			selectOption: null,
			resizeObserver: null,
		};
	},

	computed: {
		...mapState('ecommerce', ['shoppingCartItems']),
		textAlign() {
			return this.blockStyle?.textAlign;
		},
		priceData() {
			return this.selectedOption
				? this.selectedOption.value.variants[0].prices[0]
				: this.productData?.variants[0].prices[0];
		},
		productOptions() {
			return this.productData?.variants.map((variant) => ({
				value: {
					...this.productData,
					variants: [variant],
				},
				label: variant.options?.length ? variant.options[0].value : variant.title,
			}));
		},
		selectedOption: {
			get() {
				if (this.selectOption) {
					return this.selectOption;
				}

				if (this.productData) {
					return this.productOptions[0];
				}

				return null;
			},
			set(value) {
				this.selectOption = value;
			},
		},
		computedStyles() {
			return {
				...objectToCssVariables({
					...this.textColorVars,
					...this.blockButtonStyle,
				}),
			};
		},
		showPrice() {
			return !this.productData?.options.length || (this.productData?.options.length && this.selectedOption);
		},
		isButtonDisabled() {
			return this.isBookingProductInCart || !this.canAddToCart(this.productData?.id, this.selectedOption?.value.variants[0].id);
		},
		buttonText() {
			if (this.isBookingProductInCart) {
				return `\u2713 ${this.translations.booked}`;
			}

			return this.blockButtonText || this.translations?.addToBag || 'Add to bag';
		},
		isProductTypeBooking() {
			return this.productData?.type.value === PRODUCT_TYPE_BOOKING;
		},
		location() {
			return this.productData?.variants[0].booking_event?.location;
		},
		formattedBookingDuration() {
			return getFormattedBookingDuration(this.productData, this.translations);
		},
		isBookingProductInCart() {
			return this.isProductTypeBooking && this.shoppingCartItems?.some((item) => item.id === this.productData?.id);
		},
	},

	methods: {
		formatPrice,
		handleButtonClick() {
			this.$emit('buy-button-click', this.selectedOption ? this.selectedOption.value : this.productOptions[0].value);
		},
	},
});
</script>

<style lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@include font-style("h3", ".block-product__title", ".block-product");
@include font-style("h5", ".block-product__subtitle", ".block-product");
@include font-style("body", "p,.option-select", ".block-product");
@include font-style("body-large", ".body-large", ".block-product");

@mixin button-settings($type) {
	// negative margins that are added the same as border width prevents content above and below button from shifting
	margin: calc(-1 * var(--grid-button-#{$type}-border-null, var(--grid-button-#{$type}-border-width, 0)));

	&:hover,
	&:focus {
		margin: calc(-1 * var(--grid-button-#{$type}-border-null-hover, var(--grid-button-#{$type}-border-width-hover, 0)));
	}
}

.block-product-wrapper {
	z-index: $z-index-user--block-grid;
	padding: var(--block-padding);
}

.block-product {
	$this: &;

	display: flex;
	justify-content: center;
	width: 100%;
	max-width: var(--content-width);
	margin: 0 auto;

	&__content-wrapper {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		justify-content: center;
		width: 100%;
		max-width: 624px;
		padding-left: 80px;
		word-break: break-word;

		&--centered {
			align-items: center;
			text-align: center;
		}
	}

	&__button {
		position: relative;
		display: flex;
		align-items: center;
		height: var(--button-height);

		&--primary {
			@include button-settings("primary");
		}

		&--secondary {
			@include button-settings("secondary");
		}
	}

	&__title {
		margin-bottom: 8px;
	}

	&__subtitle {
		margin-bottom: 16px;
	}

	&__price-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		&--with-duration {
			margin-bottom: 8px;
		}
	}

	&__additional-info {
		display: flex;
	}

	&__price {
		margin-bottom: 0;

		&--sale {
			margin-right: 8px;
			opacity: 0.4;

			&#{&} {
				text-decoration: line-through;
			}
		}
	}

	&__duration {
		&::before {
			margin: 0 8px;
			content: "|";
		}
	}

	&__location,
	&__notice {
		margin-bottom: 32px;
		opacity: 0.6;
	}

	&__notice {
		margin-top: 8px;
		font-size: 14px;
	}

	&__description {
		margin-top: 56px;
		word-break: break-word;
		white-space: pre-line;

		blockquote {
			position: relative;
			display: flex;
			font-style: italic;

			&::before {
				margin-right: 0.3em;
				font-size: 4em;
				line-height: 1em;
				content: open-quote;
			}

			&::after {
				visibility: hidden;
				content: close-quote;
			}
		}

		ul,
		ol {
			padding-left: 1em;
			list-style-position: inside;
		}
	}
}

@include site-engine-mobile {
	.block-product-wrapper {
		padding: var(--m-block-padding);
	}

	.block-product {
		flex-direction: column;
		width: 100%;

		&--centered {
			align-items: center;
		}

		&__content-wrapper {
			max-width: unset;
			padding: 32px 0 0;
		}

		&__price-wrapper {
			margin-bottom: 32px;

			&--with-duration {
				margin-bottom: 8px;
			}
		}
	}
}
</style>
