import {
	META_ECOMMERCE_TYPE,
	ECOMMERCE_TYPE_ZYRO,
} from '@zyro-inc/site-modules/constants';
import { getIsLocaleWithEcommerce } from '@zyro-inc/site-modules/utils/getters/getIsLocaleWithEcommerce';
import {
	getStoreProducts,
	getVariantsQuantity,
} from '@/api/EcommerceApi';
import {
	PRODUCT_TYPE_BOOKING,
	DEMO_ECOMMERCE_STORE_ID,
} from '@zyro-inc/site-modules/constants/ecommerce';

const SHOPPING_CART_STORAGE_KEY = 'shopping-cart-items';
const SHOPPING_CART_TTL = 86400000; // 1 day

// mutation type constants:
export const SET_STORE_PRODUCTS = 'SET_STORE_PRODUCTS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_CHECKOUT_LOADING = 'SET_IS_CHECKOUT_LOADING';
export const SET_IS_LOADED = 'SET_IS_LOADED';
export const SET_SHOPPING_CART_OPEN = 'SET_SHOPPING_CART_OPEN';
export const SET_SHOPPING_CART_ITEMS = 'SET_SHOPPING_CART_ITEMS';
export const SET_SELECTED_BOOKING_ID = 'SET_SELECTED_BOOKING_ID';
export const SET_VARIANTS_QUANTITY = 'SET_VARIANTS_QUANTITY';

export default {
	namespaced: true,
	state: {
		products: [],
		shoppingCartItems: [],
		isShoppingCartOpen: false,
		isCheckoutLoading: false,
		isLoading: false,
		isLoaded: false,
		selectedBookingProductId: null,
		variantsQuantity: [],
	},
	getters: {
		isStoreTypeZyro: (state, getters, rootState, rootGetters) => rootGetters
			.siteMeta[META_ECOMMERCE_TYPE] === ECOMMERCE_TYPE_ZYRO,
		isEcommerceStoreCreated: (state, getters, rootState, rootGetters) => !!rootGetters.siteMeta?.ecommerceStoreId,
		isLocaleWithEcommerceItems: (state, getters, rootState, rootGetters) => getIsLocaleWithEcommerce({
			blocks: rootGetters.currentLanguageData.blocks,
			elements: rootGetters.currentLanguageData.elements,
		}),
		quantifiedCartItemsList: (state) => state.shoppingCartItems.reduce((quantifiedProducts, product) => {
			const existingProduct = quantifiedProducts.find(
				(searchedProduct) => searchedProduct.product.variants[0].id === product.variants[0].id,
			);

			if (existingProduct) {
				return quantifiedProducts.map((productToUpdate) => {
					if (productToUpdate.product.variants[0].id === product.variants[0].id) {
						return {
							...productToUpdate,
							quantity: productToUpdate.quantity + 1,
						};
					}

					return productToUpdate;
				});
			}

			return [
				...quantifiedProducts,
				{
					product,
					quantity: 1,
				},
			];
		}, []),
		canAddToCart: (state, getters) => (productId, productVariantId) => {
			const product = state.products.find((item) => item.id === productId);
			const variant = product?.variants.find((item) => item.id === productVariantId);

			if (!product || !variant) {
				return false;
			}

			if (variant.manage_inventory) {
				const cartProduct = getters.quantifiedCartItemsList
					.find((productItem) => productItem.product.id === productId
						&& productItem.product.variants.some((variantItem) => variantItem.id === variant.id));
				const quantity = cartProduct?.quantity || 0;
				const availableQuantity = state.variantsQuantity.find((item) => item.id === productVariantId)?.inventory_quantity;

				return quantity < availableQuantity;
			}

			return true;
		},
		isCartVisible: (state, getters, rootState, rootGetters) => rootGetters.currentLanguageData.blocks.header?.settings?.isCartVisible,
	},
	mutations: {
		[SET_IS_LOADING](state, isLoading) {
			state.isLoading = isLoading;
		},
		[SET_IS_LOADED](state, isLoaded) {
			state.isLoaded = isLoaded;
		},
		[SET_IS_CHECKOUT_LOADING](state, isLoading) {
			state.isCheckoutLoading = isLoading;
		},
		[SET_STORE_PRODUCTS](state, products) {
			state.products = products;
		},
		[SET_SHOPPING_CART_OPEN](state, isShoppingCartOpen) {
			state.isShoppingCartOpen = isShoppingCartOpen;
		},
		[SET_SHOPPING_CART_ITEMS](state, shoppingCartItems) {
			state.shoppingCartItems = shoppingCartItems;
		},
		[SET_SELECTED_BOOKING_ID](state, productId) {
			state.selectedBookingProductId = productId;
		},
		[SET_VARIANTS_QUANTITY](state, variantsQuantity) {
			state.variantsQuantity = variantsQuantity;
		},
	},
	actions: {
		getProducts: async ({
			state,
			commit,
			dispatch,
			rootGetters,
		}) => {
			const storeId = rootGetters.siteMeta.ecommerceStoreId
				?? rootGetters.siteMeta.demoEcommerceStoreId
				?? DEMO_ECOMMERCE_STORE_ID;

			if (!storeId) {
				return;
			}

			commit(SET_IS_LOADING, true);

			try {
				const storeProducts = await getStoreProducts(storeId);
				const variantsQuantity = await getVariantsQuantity(storeId);

				const refreshCartItems = (products) => state.shoppingCartItems.reduce((accumulator, cartItem) => {
					const productMatch = products.find((productItem) => productItem.id === cartItem.id);
					const variantMatch = productMatch?.variants
						.find((variantItem) => cartItem.variants
							.some((cartVariantItem) => variantItem.id === cartVariantItem.id));
					const quantity = accumulator.reduce((counter, item) => {
						if (item.variants.some((variant) => variant.id === variantMatch?.id)) {
							return counter + 1;
						}

						return counter;
					}, 0);

					const availableQuantity = variantsQuantity.find((variant) => variant.id === variantMatch?.id)?.inventory_quantity;
					const isQuantityValid = !variantMatch?.manage_inventory || quantity < availableQuantity;
					const bookingEvent = productMatch?.type.value === PRODUCT_TYPE_BOOKING ? {
						...cartItem.variants[0].booking_event,
						time_slot: cartItem.variants[0].booking_event.time_slot,
						date: cartItem.variants[0].booking_event.date,
					} : null;

					if (productMatch && variantMatch && isQuantityValid) {
						return [
							...accumulator,
							{
								...productMatch,
								variants: [
									{
										...variantMatch,
										booking_event: bookingEvent,
									},
								],
							},
						];
					}

					return accumulator;
				}, []);

				commit(SET_STORE_PRODUCTS, storeProducts);
				commit(SET_VARIANTS_QUANTITY, variantsQuantity);
				dispatch('setShoppingCartItems', refreshCartItems(storeProducts));
			} catch (error) {
				console.error(error);
			} finally {
				commit(SET_IS_LOADING, false);
				commit(SET_IS_LOADED, true);
			}
		},
		setShoppingCartOpen({ commit }, isShoppingCartOpen) {
			commit(SET_SHOPPING_CART_OPEN, isShoppingCartOpen);
		},
		setShoppingCartItems({ commit }, shoppingCartItems) {
			const storageValue = {
				payload: shoppingCartItems,
				expiry: Date.now() + SHOPPING_CART_TTL,
			};

			window.localStorage.setItem(SHOPPING_CART_STORAGE_KEY, JSON.stringify(storageValue));

			commit(SET_SHOPPING_CART_ITEMS, shoppingCartItems);
		},
		setIsLoading({ commit }, isLoading) {
			commit(SET_IS_LOADING, isLoading);
		},
		setIsCheckoutLoading({ commit }, isLoading) {
			commit(SET_IS_CHECKOUT_LOADING, isLoading);
		},
		setSelectedBookingId({ commit }, productId) {
			commit(SET_SELECTED_BOOKING_ID, productId);
		},
		updateShoppingCartItems({ commit }) {
			const storageValue = window.localStorage.getItem(SHOPPING_CART_STORAGE_KEY);

			if (!storageValue) {
				return;
			}

			const item = JSON.parse(storageValue);

			if (Date.now() > item.expiry) {
				window.localStorage.removeItem(SHOPPING_CART_STORAGE_KEY);

				commit(SET_SHOPPING_CART_ITEMS, []);

				return;
			}

			commit(SET_SHOPPING_CART_ITEMS, item.payload);
		},
	},
};
