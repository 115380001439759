<template>
	<EcommerceShoppingCart
		v-qa="'user-section-zyroecommerceshoppingcart'"
		:is-shopping-cart-open="isShoppingCartOpen"
		:is-loading="isCheckoutLoading"
		:header-height="headerHeight"
		:translations="ecommerceTranslations"
		:language="language"
		@checkout-button-click="initCheckout"
	/>
</template>

<script>
import {
	mapActions,
	mapState,
} from 'vuex';

import EcommerceShoppingCart from '@zyro-inc/site-modules/components/ecommerce/EcommerceShoppingCart.vue';
import { isInPreviewIframe } from '@zyro-inc/site-modules/utils/isInPreviewIframe';

import { useEcommerceModal } from '@/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@/components/ecommerce/useEcommerce';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		EcommerceShoppingCart,
	},

	props: {
		headerHeight: {
			type: Number,
			default: 0,
		},
		ecommerceTranslations: {
			type: Object,
			default: () => ({}),
		},
		language: {
			type: String,
			default: 'en',
		},
	},

	setup(props) {
		const { openEcommerceModal } = useEcommerceModal(props);
		const { initiateCheckout } = useEcommerce(props);

		return {
			openEcommerceModal,
			initiateCheckout,
		};
	},

	computed: {
		...mapState('ecommerce', [
			'isShoppingCartOpen',
			'isCheckoutLoading',
			'shoppingCartItems',
		]),
	},

	created() {
		this.getProducts();
	},

	methods: {
		...mapActions('ecommerce', [
			'getProducts',
			'setShoppingCartOpen',
			'setShoppingCartItems',
			'setIsCheckoutLoading',
		]),
		async initCheckout() {
			if (isInPreviewIframe) {
				this.openEcommerceModal('EcommerceMessageButtonDisabled');

				return;
			}

			if (this.isCheckoutLoading) {
				return;
			}

			this.setIsCheckoutLoading(true);

			await this.initiateCheckout(this.shoppingCartItems).then(() => {
				this.setShoppingCartOpen(false);
				this.setIsCheckoutLoading(false);
			});
		},
	},
});
</script>
