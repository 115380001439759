<template>
	<div>
		<div id="fb-root" />
		<div
			class="fb-customerchat"
			attribution="install_email"
			:page_id="pageId"
		/>
	</div>
</template>

<script>
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';
import { FACEBOOOK_MESSENGER_SCRIPT_VERSION } from '@/components/metas/constants';
import {
	facebookMessengerScript,
	facebookMessengerScriptAsync,
} from '@/components/metas/integrations/scripts';

import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		pageId: {
			type: [
				Number,
				String,
			],
			required: true,
		},
		locale: {
			type: String,
			default: null,
		},
	},

	metaInfo() {
		return {
			script: isAppPrerendering
				? []
				: [
					facebookMessengerScript({
						version: FACEBOOOK_MESSENGER_SCRIPT_VERSION,
						locale: this.locale,
					}),
					facebookMessengerScriptAsync({
						locale: this.locale,
					}),
				],
		};
	},
});
</script>

<style lang="scss">
// This class comes from Facebook Messenger integration script
#fb-root {
	position: relative;
	z-index: $z-index-user--integration;
}
</style>
